import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useHotkeys } from "react-hotkeys-hook"
import PrivateRoute from "../../components/private-route"
import SEO from "../../components/seo"
// import Layout from "../../components/templates/layout"
import Layout from "../../components/layout/Layout"
import ClientView from "../../components/views/dashboard/nail/clients"

const ClientsPage = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        <ClientView />
      </Layout>
    </DndProvider>
  )
}

export default ClientsPage
