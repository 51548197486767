import React from "react"
import { SearchBar } from "rsuite/esm/Picker"
import GearIcon from "../../../fundamentals/icons/gear-icon"
import SearchIcon from "../../../fundamentals/icons/search-icon"
import SortingIcon from "../../../fundamentals/icons/sorting-icon"
import Select from "../../../molecules/select/next-select/select"
import ClientsTable from "../../../tables/ClientsTable"

const ClientView = () => {
  return (<React.Fragment>
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-10 p-2 justify-between">
        <div>
          <div className="font-extrabold text-xl">Clients list</div>
          <div className="text-lg">View, add, edit and delete your clients'details. <span className="text-blue-400">Learn more</span></div>
        </div>

      </div>
      <div className="col-span-1">
        <Select placeholder='Options' size="md" options={[]}
          onChange={(e) => { console.log(e) }}
          value={null} />
        {/* <button className="bg-blue-400 text-white rounded-md p-2">Add client</button> */}
      </div>
      <div className="col-span-1">
        <button className="bg-black text-white rounded-md p-2">Add client</button>
      </div>

      <div className="grid grid-cols-12 bg-gray-100 rounded-lg shadow col-span-12 p-4">
        <div className="col-span-7">
          <div className="font-bold text-lg mt-3">Import clients</div>
          <div className="text-lg mt-3">Import your client list in minute to avoid new client fees for your existing clients when they book on the marketplace</div>
          <div>
            <button className="bg-white text-lg font-bold rounded-md p-4 mt-3 border">Start now</button>
          </div>

        </div>
      </div>

      <div className="bg-gray-100 rounded-xl p-4 grid grid-cols-12 col-span-12 gap-4">
        <div className="col-span-4 grid grid-cols-12 bg-white rounded-full p-2 border">
          <div className="flex items-center">
            <SearchIcon />
          </div>
          <input className="bg-white border-none w-full h-8 focus:border-none col-span-11 outline-none" placeholder="search" />
          {/* hello */}
          {/* <SearchBar /> */}
        </div>

        <div className="grid grid-cols-12 bg-white rounded-full py-2 px-3 border">
          <div className="font-bold col-span-8 flex items-center">Filters</div>
          <div className="col-span-4 flex items-center"><GearIcon /> </div>
        </div>

        <div className="col-span-4"></div>

        {/* <div className="col-span-3"> */}
        <div className="grid grid-cols-12 bg-white rounded-full py-2 px-3 border col-span-3">
          <div className="col-span-11 font-bold text-md items-center flex">
            Created at (Newest first)
          </div>
          <div className="col-span-1 flex items-center">
            <SortingIcon />
          </div>
        </div>
      </div>

      <div className="col-span-12 p-4">
        <ClientsTable data={[{
          id: 1,
          reviews: 1,
          gender: 1,
          clientName: 1,
          totalSales: 20
        }]} />
      </div>
    </div>
  </React.Fragment>
  )
}

export default ClientView